import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Image from '../Image';

const NewsItem = ({ image, intl: { formatMessage } }) => {
    const text = `actu.${image.name}.text`;
    const title = `actu.${image.name}.title`;
    return (
        <div className='news-item'>
            <div className='img-container'>
                <Image
                    alt={formatMessage({
                        id: title,
                    })}
                    image={image.childImageSharp.gatsbyImageData}
                    objectFit='contain'
                />
            </div>
            <div className='info'>
                <FormattedMessage id={title} tagName='h2' />
                <FormattedMessage
                    id={text}
                    tagName='h3'
                    values={{
                        breakingLine: <br />,
                    }}
                />
                <div className='corner-top' />
                <div className='corner-bottom' />
            </div>
        </div>
    );
};

NewsItem.propTypes = {
    image: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(NewsItem);
