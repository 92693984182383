/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Slider.styl';
import Card from '../Card';
import NewsItem from './NewsItem';
// import NewsItem from './NewsItem';

const SliderNews = ({
    autoplay,
    data,
    intl: { formatMessage },
    isDark,
    slidesToShow,
}) => {
    const size = useContext(ResponsiveContext);
    const isSmall = size === 'small';

    const ArrowNext = ({ onClick, ...props }) => (
        <button
            className='chevron next'
            onClick={onClick}
            aria-label={formatMessage({ id: 'buttons.next' })}
        >
            <i className='icon icon-navigate_nextchevron_right' />
        </button>
    );
    const ArrowPrev = ({ onClick, ...props }) => (
        <button
            className='chevron prev'
            onClick={onClick}
            aria-label={formatMessage({ id: 'buttons.prev' })}
        >
            <i className='icon icon-navigate_beforechevron_left' />
        </button>
    );

    const settings = {
        arrows: !isSmall,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: true,
        infinite: true,
        lazyLoad: true,
        nextArrow: null,
        prevArrow: null,
        rows: 1,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 1500,
    };

    return (
        <div className='slider-container'>
            <Slider {...settings} className={clsx('slider-news', { isDark })}>
                {data.map((item, i) => (
                    <NewsItem key={`slider-news-${i}`} image={item} />
                ))}
            </Slider>
        </div>
    );
};

SliderNews.defaultProps = {
    autoplay: false,
    isDark: false,
    slidesToShow: 4,
};

SliderNews.propTypes = {
    autoplay: PropTypes.bool,
    data: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    isDark: PropTypes.bool,
    slidesToShow: PropTypes.number,
};

export default injectIntl(SliderNews);
