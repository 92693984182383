import clsx from 'clsx';
import { Box, ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import './SectionHome.styl';
import Container from '../Container';
import FormattedMessageWithClassName from '../FormattedMessageWithCN';

const MyBox = styled(Box)`
    background: ${(props) => props.backgroundColor};
`;

const SectionSlider = ({ backgroundColor, children, description, title }) => {
    const size = useContext(ResponsiveContext);
    const isSmall = size === 'small';
    return (
        <MyBox
            fill='horizontal'
            className={clsx('section-home', { small: isSmall })}
            backgroundColor={backgroundColor}
        >
            <Container pad={{ bottom: 'xlarge', top: 'large' }}>
                <FormattedMessageWithClassName
                    id={title}
                    tagName='h2'
                    className='title'
                />
                <FormattedMessageWithClassName
                    id={description}
                    tagName='h3'
                    className='description'
                />
                {children}
            </Container>
        </MyBox>
    );
};

SectionSlider.defaultProps = {
    backgroundColor: 'white',
};

SectionSlider.propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default SectionSlider;
