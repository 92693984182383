import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './index.styl';
// import { recentProductsOrder } from '../../content/recentProducts';
import { shuffle } from '../utils/arrays';
import SEO from '../views/components/SEO';
import SectionHome from '../views/components/SectionHome';
import ProductGrid from '../views/components/SectionHome/ProductGrid';
import Slider from '../views/components/Slider';
import Hero from '../views/layouts/Hero';
import MainLayout from '../views/layouts/MainLayout';
import SliderNews from '../views/components/SliderNews/index';

const IndexPage = ({
    data,
    location,
    pageContext: { locale, translations },
}) => {
    const news = data.news.edges.map((n) => n.node);
    // const ordered = mapOrder(
    //     data.recentProducts.group,
    //     recentProductsOrder,
    //     'fieldValue',
    // );
    // const merged = [];
    // ordered.forEach((n) => merged.push(...n.edges));
    // const recentProducts = merged.map((n) => n.node);
    const recentProducts = data.recentProducts.edges.map((n) => n.node);
    shuffle(recentProducts);

    return (
        <MainLayout locale={locale} translationsPaths={translations}>
            <SEO
                title='home.headerTitle'
                location={location}
                translationsPaths={translations}
            />
            <Hero
                image={data.hero.childImageSharp.gatsbyImageData}
                colors={data.hero.fields.colors}
                title='home.hero.h1'
                text1='home.hero.p1'
                text2='home.hero.p2'
            />

            <main id='content'>
                <SectionHome
                    title='home.section.collection.title'
                    description='home.section.collection.description'
                    list={[]}
                >
                    <SliderNews data={news} isDark />
                </SectionHome>

                <SectionHome
                    title='home.section.recent.title'
                    description='home.section.recent.description'
                    list={[]}
                    backgroundColor='linear-gradient(to right, #e6dfdb 0%, #b9b5c2 100%);'
                >
                    <Slider data={recentProducts} autoplay isDark />
                </SectionHome>

                <SectionHome
                    title='home.section.products.title'
                    description='home.section.products.description'
                    list={[]}
                >
                    <ProductGrid data={data} />
                </SectionHome>
            </main>
        </MainLayout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        calendars: PropTypes.object.isRequired,
        cards: PropTypes.object.isRequired,
        hero: PropTypes.object.isRequired,
        items: PropTypes.object.isRequired,
        news: PropTypes.object.isRequired,
        recentProducts: PropTypes.object.isRequired,
        stationery: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
    query ($locale: String!, $recentProducts: [String!]!) {
        hero: file(
            sourceInstanceName: { eq: "hero" }
            relativePath: { eq: "home.jpg" }
        ) {
            ...BgImages
        }
        cards: file(sourceInstanceName: { eq: "app" }, name: { eq: "cards" }) {
            ...HomeImages
        }
        stationery: file(
            sourceInstanceName: { eq: "app" }
            name: { eq: "stationery" }
        ) {
            ...HomeImages
        }
        items: file(sourceInstanceName: { eq: "app" }, name: { eq: "items" }) {
            ...HomeImages
        }
        calendars: file(
            sourceInstanceName: { eq: "app" }
            name: { eq: "calendars" }
        ) {
            ...HomeImages
        }
        recentProducts: allProducts(
            filter: { lang: { eq: $locale }, image: { in: $recentProducts } }
        ) {
            edges {
                node {
                    ...ProductCardHome
                }
            }
        }
        news: allFile(
            filter: { sourceInstanceName: { eq: "actu" } }
            sort: { order: ASC, fields: relativeDirectory }
        ) {
            edges {
                node {
                    ...HomeNews
                    name
                }
            }
        }
    }
`;
