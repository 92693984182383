/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import { Grid, Box, ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    PAGE_CARTERIE,
    PAGE_PAPETERIE,
    PAGE_OBJET_CADEAU,
    PAGE_CALENDAR,
} from '../../../constants/router';
import LocalizedLink from '../LocalizedLink';
import Image from '../Image';

const ProductGrid = ({ data }) => {
    const size = useContext(ResponsiveContext);
    const isSmall = size === 'small';

    const products = [
        { link: PAGE_CARTERIE, title: 'cards' },
        { link: PAGE_PAPETERIE, title: 'stationery' },
        { link: PAGE_OBJET_CADEAU, title: 'items' },
        { link: PAGE_CALENDAR, title: 'calendars' },
    ];

    const rows = isSmall
        ? ['small', 'small', 'small', 'small']
        : ['medium', 'medium'];

    const columns = isSmall ? ['auto'] : ['flex', 'flex', 'flex'];

    const areas = isSmall
        ? [
              {
                  end: [0, 0],
                  name: 'cards',
                  start: [0, 0],
              },
              {
                  end: [0, 1],
                  name: 'stationery',
                  start: [0, 1],
              },
              {
                  end: [0, 2],
                  name: 'items',
                  start: [0, 2],
              },
              {
                  end: [0, 3],
                  name: 'calendars',
                  start: [0, 3],
              },
          ]
        : [
              {
                  end: [0, 1],
                  name: 'cards',
                  start: [0, 0],
              },
              {
                  end: [1, 1],
                  name: 'stationery',
                  start: [1, 0],
              },
              {
                  end: [2, 0],
                  name: 'items',
                  start: [2, 0],
              },
              {
                  end: [2, 1],
                  name: 'calendars',
                  start: [2, 1],
              },
          ];

    return (
        <Grid
            rows={rows}
            columns={columns}
            gap='small'
            areas={areas}
            // fill='horizontal'
        >
            {products.map((item, i) => (
                <Box key={`home-product-link-${i}`} gridArea={item.title}>
                    <LocalizedLink
                        to={item.link}
                        className='home-product-link'
                        hasSlug
                        style={{
                            alignSelf: 'strech',
                            display: 'flex',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <Image
                            alt={item.title}
                            image={
                                data[item.title].childImageSharp.gatsbyImageData
                            }
                            className={clsx('home-product-bg', {
                                small: isSmall,
                            })}
                            // layout='fullWidth'
                            objectFit='cover'
                            objectPosition='50% 50%'
                            style={{
                                height: '100%',
                                overflow: 'initial',
                                position: 'absolute',
                                width: '100%',
                            }}
                        />
                        <Box
                            pad={{ vertical: 'medium' }}
                            fill='vertical'
                            align='center'
                            justify='end'
                            style={{ position: 'relative', width: '100%' }}
                        >
                            <FormattedMessage
                                tagName='h2'
                                id={`product.sections.${item.title}`}
                            />
                        </Box>
                    </LocalizedLink>
                </Box>
            ))}
        </Grid>
    );
};

ProductGrid.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ProductGrid;
